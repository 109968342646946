import React from "react";
import { Typewriter } from "react-simple-typewriter";
import { FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";
import ParticlesBackground from "./ParticlesBackground";

const Hero = () => {
  return (
    <div className="relative h-screen w-full overflow-hidden">
      <div className="absolute top-0 left-0 w-full h-full z-0">
        <ParticlesBackground />
      </div>

      <section className="relative z-10 h-full flex flex-col justify-center items-center p-4">
        <div className="max-w-screen-xl w-full mx-auto">
          <div className="text-center">
            <h1 className="mb-2 text-3xl font-extrabold tracking-tight leading-none sm:text-4xl md:text-5xl lg:text-6xl xl:text-8xl dark:text-white">
              Hi, I am
            </h1>
            <h1 className="mb-4 text-3xl font-extrabold tracking-tight leading-none sm:text-4xl md:text-5xl lg:text-6xl xl:text-8xl text-neonBlue">
              Fredy.
            </h1>

            <div className="flex flex-wrap justify-center items-center gap-4 mb-6">
              <Link
                to="/resume"
                className="text-sm sm:text-base inline-flex items-center justify-center px-4 py-2 font-medium text-center text-white rounded-lg bg-neonPink hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
              >
                My Resume
              </Link>
              <div className="flex space-x-4">
                <a
                  href="https://www.linkedin.com/in/fredysorto/"
                  className="text-white"
                  target="blank"
                >
                  <FaLinkedin size={28} />
                </a>
                <a
                  href="https://github.com/fredygsorto"
                  className="text-white"
                  target="blank"
                >
                  <FaGithub size={28} />
                </a>
                <a href="mailto:fredygsorto@gmail.com" className="text-white">
                  <FaEnvelope size={28} />
                </a>
              </div>
            </div>

            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-4 text-white flex flex-wrap justify-center items-center">
              <span>I am a&nbsp;</span>
              <span className="text-neonBlue font-bold inline-flex items-center min-h-[1.5em]">
                <Typewriter
                  words={[
                    "Full Stack Developer.",
                    "Software Engineer.",
                    "Mobile Developer.",
                    "Web Developer.",
                    "Technology Associate.",
                  ]}
                  loop={true}
                  cursor
                  cursorStyle="|"
                  typeSpeed={100}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </span>
            </h1>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
